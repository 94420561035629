<div class="main">
    <div class="left" [ngClass]="isAtLeastMedium ? 'flex-basis-45' : 'flex-basis-100'">
        <div class="body">
            <router-outlet></router-outlet>
        </div>

        <div class="footer">
            <div>© AEC</div>
            <div class="contact"><i class="icon-mail-01"></i>contact&#64;aecenergie.fr</div>
        </div>
    </div>

    <div class="right" [ngClass]="isAtLeastMedium ? 'd-flex' : 'd-none'">
        <div class="slogan-container">
            <div class="logo">
                <img src="../../../assets/img/logo_header.png" alt="logo" />
            </div>
            <div class="slogan-text">
                Votre outil de contrôle de concession
            </div>
        </div>
        <div class="image-container">
            <div class="image"></div>
        </div>
    </div>
</div>
