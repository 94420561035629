import { Component, Input, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
    Observable,
    distinctUntilChanged,
    tap,
    switchMap,
    of,
    debounceTime,
    filter,
    map,
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { User } from '../../models/user.model';
import { AuthState, Logout } from '../../states/auth.state';

import { SearchService } from 'src/app/core/services/search.service';
import { SidenavService } from 'src/app/shared/services/sidenav.service';
import { NavigationEnd, Router } from '@angular/router';
import {
    GetAllOrganizations,
    OrganizationState,
} from 'src/app/features/admin/organization/organization.state';
import { Organization } from '../../models/organization.model';
import { OrganizationService } from 'src/app/features/admin/organization/organization.service';

@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() currentPage!: string;
    public projectName = ' ';

    public isCollapsed: boolean = true;
    public headerFormGroup: FormGroup;

    public loggedUser$!: Observable<User | null>;
    public loggedUser!: User;

    public searchValue: string = '';
    public isSearching: boolean = false;
    public searchResults: any[] = [];

    public selectOptions = [
        {
            label: 'Contrôle de concession',
            value: 'cc',
        },
    ];

    public selectListOrga: { label: string; value: string }[] = [];
    organizations$: Observable<Organization[]> = inject(Store)
        .select(OrganizationState.organizations)
        .pipe(filter((organizations): organizations is Organization[] => organizations !== null));

    public yearOptions = [{ label: '2024', value: '2024' }];
    public initials$: Observable<string> | undefined;

    public avatarUrl$: Observable<string> | undefined;
    public avatarUrl: string | null = null;
    public isFetchingAvatar: boolean = false;

    public isSmallScreen!: boolean;

    public organization: Organization | undefined | null;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly store: Store,
        private readonly searchService: SearchService,
        private readonly sidenavService: SidenavService,
        private router: Router,
        private organizationService: OrganizationService,
    ) {
        inject(BreakpointObserver)
            .observe(sidenavService.breakPointsThreshold)
            .pipe(untilDestroyed(this))
            .subscribe((result) => {
                const matches = result.matches;
                this.isSmallScreen = matches;
            });

        this.headerFormGroup = this.formBuilder.group({
            mySelectControl: ['cc', Validators.required],
            mySelect: ['', Validators.required],
            yearSelect: ['2024', Validators.required],
            myInput: '',
        });
    }

    ngOnInit() {
        this.loggedUser$ = this.store.select(AuthState.loggedUser);
        this.loggedUser$.subscribe((data: any) => {
            this.loggedUser = data;

            if (this.loggedUser && this.loggedUser.isAdmin) {
                this.store.dispatch(new GetAllOrganizations());
                this.organizations$
                    .pipe(
                        map((organizations) =>
                            organizations.map((org) => ({
                                label: org.name,
                                value: org.id,
                            })),
                        ),
                    )
                    .subscribe((selectOptions) => {
                        this.selectListOrga = selectOptions;
                    });
            }
        });

        this.headerFormGroup
            .get('mySelect')
            ?.valueChanges.pipe(untilDestroyed(this))
            .subscribe((value) => console.log('select', value));

        this.headerFormGroup
            .get('myInput')
            ?.valueChanges.pipe(
                distinctUntilChanged(),
                debounceTime(300),
                tap(() => {
                    this.isSearching = true;
                }),
                switchMap((term: string | { name: string; code: string }) => {
                    if (typeof term == 'object') {
                        term = term.name;
                    }

                    if (term.length < 3) {
                        return of([]);
                    }
                    return this.searchService.search(term);
                }),
                tap(() => (this.isSearching = false)),
                untilDestroyed(this),
            )
            .subscribe((value) => (this.searchResults = value));

        this.initials$ = this.loggedUser$.pipe(
            filter(Boolean),
            distinctUntilChanged(),
            map((user) => (user.firstname?.charAt(0) || '') + (user.lastname?.charAt(0) || '')),
            untilDestroyed(this),
        );

        // this.loggedUser$
        //     .pipe(
        //         filter(Boolean),
        //         distinctUntilChanged(),
        //         switchMap((user) =>
        //             this.organizationService
        //                 .getAvatar(user.organization!.id!)
        //                 .pipe(map((blob) => (!!blob ? URL.createObjectURL(blob) : null))),
        //         ),
        //         untilDestroyed(this),
        //     )
        //     .subscribe((avatarUrl) => {
        //         this.isFetchingAvatar = false;
        //         this.avatarUrl = avatarUrl;
        //         this.organizationService.setAvatarUrl(this.avatarUrl);
        //     });

        // this.organizationService.avatarUrl$.pipe(untilDestroyed(this)).subscribe((avatarUrl) => {
        //     if (avatarUrl) {
        //         this.avatarUrl = avatarUrl;
        //     }
        // });

        this.store.select(OrganizationState.getOrganization).subscribe((organization) => {
            this.organization = organization;
            if (this.organization?.id) {
                this.headerFormGroup.get('mySelect')?.setValue(this.organization.id.toString());
            }
        });

        this.headerFormGroup.get('mySelect')?.valueChanges.subscribe((selectedValue) => {
            if (selectedValue) {
                this.router.navigate([`/admin/organization-detail/${selectedValue}/general`]);
            }
        });

        this.organizationService.organization$
            .pipe(untilDestroyed(this))
            .subscribe((organization) => {
                if (!organization) {
                    return;
                }
                this.organization = organization;
            });

        this.organizationService.updateListOrga$.pipe(untilDestroyed(this)).subscribe(() => {
            this.store.dispatch(new GetAllOrganizations());
        });

        this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.currentPage = this.getPageName(event.urlAfterRedirects);
            }
        });
    }

    public toggleSidenav() {
        this.sidenavService.toggle();
    }

    public openModalUpdateUser(): void {
        this.router.navigate(['/main/account']);
    }

    public logOut(): void {
        this.store.dispatch(new Logout());
    }

    public displayFn(option: { name: string; code: string }): string {
        const display = option && option.name ? option.name : '';
        return display;
    }

    private getPageName(url: string): string {
        if (url.includes('admin')) {
            return 'admin';
        } else {
            return 'other';
        }
    }
}
