import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, tap, catchError, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService, ButtonColors, LayerService } from '@ed---interne/ng-uui-components';
import { checkPassword } from '../../../../shared/form.helpers';
import { passwordValidator } from '../../../../shared/validators/password.validator';

import { ChangePassword } from 'src/app/features/admin/users/users.state';
import { AuthState } from '../../../../core/states/auth.state';
import { User } from 'src/app/core/models/user.model';

@UntilDestroy()
@Component({
    selector: 'account-password-edit',
    templateUrl: './account-password-edit.component.html',
    styleUrls: ['./account-password-edit.component.scss'],
})
export class AccountPasswordEditComponent implements OnInit {
    public buttonColors = ButtonColors;
    public isCurrentPasswordValid = false;
    public isNewPasswordValid = false;
    public loggedUser$!: Observable<User | null>;
    public loggedUser!: User;
    public isSaving = false;

    public isCurrentPasswordVisible: boolean = false;
    public isNewPasswordVisible: boolean = false;

    public passwordFormGroup = this.formBuilder.group(
        {
            newPassword: [{ value: '', disabled: false }],
            currentPassword: ['', { validators: [passwordValidator()] }],
        },
        {
            validators: this.bothFieldsNotEmptyValidator,
        },
    );

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService,
        private readonly alertService: AlertService,
        private readonly store: Store,
    ) {}

    ngOnInit(): void {
        this.loggedUser$ = this.store.select(AuthState.loggedUser);
        this.loggedUser$
            .pipe(untilDestroyed(this))
            .subscribe((data: any) => (this.loggedUser = data));
    }

    public changePassword(): void {
        const userId = this.loggedUser.id;
        const currentPassword = this.passwordFormGroup.controls['currentPassword'].value || '';
        const newPassword = this.passwordFormGroup.controls['newPassword'].value || '';
        this.isSaving = true;
        this.store
            .dispatch(
                new ChangePassword({
                    userId: userId,
                    currentPassword: currentPassword,
                    newPassword: newPassword,
                }),
            )
            .pipe(
                tap(() => {}),
                catchError((error) => {
                    return throwError(() => error);
                }),
                untilDestroyed(this),
            )
            .subscribe({
                next: () => {
                    this.alertService.valid('Mon profil', 'Mot de passe correctement modifié !');
                    this.isSaving = false;
                    this.close();
                },
                error: (error) => {
                    console.error('Error ChangePassword', error);
                    const msg = error.error?.message;
                    if (msg == 'Current password does not match') {
                        this.alertService.error('Mon profil', 'Ancien mot de passe erroné');
                    } else {
                        this.alertService.error(
                            'Mon profil',
                            "Une erreur est survenue. Impossible de mettre à jour l'utilisateur",
                        );
                    }
                    this.isSaving = false;
                },
            });
    }

    public close(): void {
        this.layerService.close();
    }

    public toggleCurrentPasswordVisibility(): void {
        this.isCurrentPasswordVisible = !this.isCurrentPasswordVisible;
    }

    public onKeyUpCheckCurrentPassword(): void {
        const password = this.passwordFormGroup.controls['currentPassword'].value;
        if (!password) {
            return;
        }

        this.isCurrentPasswordValid = checkPassword(password);
        if (!this.isCurrentPasswordValid) {
            return;
        }

        this.passwordFormGroup.controls['newPassword'].enable();
        this.passwordFormGroup.controls['newPassword'].validator = passwordValidator();
    }

    public toggleNewPasswordVisibility(): void {
        this.isNewPasswordVisible = !this.isNewPasswordVisible;
    }

    public onKeyUpCheckNewPassword(): void {
        const password = this.passwordFormGroup.controls['newPassword'].value;
        if (!password) {
            return;
        }

        this.isNewPasswordValid = checkPassword(password);
    }

    public showPopupOnLayerClose(): boolean {
        return this.passwordFormGroup.dirty;
    }

    private bothFieldsNotEmptyValidator(group: FormGroup): { [key: string]: boolean } | null {
        const currentPassword = group.controls['currentPassword'].value?.trim();
        const newPassword = group.controls['newPassword'].value?.trim();
        return currentPassword && newPassword ? null : { fieldsEmpty: true };
    }
}
