import { Component, inject, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { filter, Observable, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatTableDataSource } from '@angular/material/table';

import {
    AlertService,
    DropdownMenuItem,
    LayerService,
    LayerSize,
    PopupService,
    PopupTypes,
} from '@ed---interne/ng-uui-components';

import { AuthState } from 'src/app/core/states/auth.state';
import { User } from 'src/app/core/models/user.model';

import { OrganizationCreateComponent } from './organization-create/organization-create.component';
import {
    DisplayedColumns,
    DisplayType,
} from 'src/app/shared/components/ed-table/ed-table.component';

import { Pagination } from 'src/app/shared/all.types';
import { LayerPopupOptions } from 'src/app/shared/all.constants';

import { OrganizationService } from 'src/app/features/admin/organization/organization.service';
import { DeleteOrganization, GetAllOrganizations, OrganizationState } from './organization.state';
import { Organization } from 'src/app/core/models/organization.model';
import { Router } from '@angular/router';

interface OrganizationTableElement {
    id: string;
    name: string;
    userCount: number;
    createdAt: Date | undefined;
    lastConnexionAt: Date | undefined;
    actions: DropdownMenuItem[];
}
enum OrganizationMenuEvent {
    AccessConcession = 'AccessConcession',
    Update = 'Update',
    Delete = 'Delete',
}
interface SettingsMenuItem {
    display: string;
    routerLink: any[];
    routerLinkActiveOptions?: any;
}

@UntilDestroy()
@Component({
    selector: 'app-organization',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.scss'],
})
export class OrganizationComponent implements OnInit {
    organizations$: Observable<Organization[]> = inject(Store)
        .select(OrganizationState.organizations)
        .pipe(filter((organizations): organizations is Organization[] => organizations !== null));

    loggedUser$: Observable<User | null> = inject(Store).select(AuthState.loggedUser);

    private loggedUser: User | undefined;
    public isLoading = false;

    public filterChanged = new Subject<string>();

    public dataSource: MatTableDataSource<OrganizationTableElement> =
        new MatTableDataSource<OrganizationTableElement>();
    public menuItems$: Observable<SettingsMenuItem[]> | undefined;

    @Select(OrganizationState.isLoading) isLoading$: Observable<boolean> | undefined;

    public displayedColumns: DisplayedColumns[] = [
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Organisation',
            objectKey: 'name',
            bold: true,
        },
        {
            displayType: DisplayType.NUMBER,
            objectDisplayName: 'Utilisateurs',
            objectKey: 'userCount',
        },
        {
            displayType: DisplayType.DATE,
            objectDisplayName: 'Date de création',
            objectKey: 'createdAt',
        },
        {
            displayType: DisplayType.DATE,
            objectDisplayName: 'Dernière connexion',
            objectKey: 'lastConnexionAt',
        },
        {
            displayType: DisplayType.BADGE,
            objectDisplayName: 'CRAC',
            objectKey: 'crac',
        },
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Contrôle de concession',
            objectKey: 'controlConcession',
        },
        {
            displayType: DisplayType.TEXT,
            objectDisplayName: 'Etudes spécifiques',
            objectKey: 'specificStudies',
        },
    ];

    public paginationInfo: Pagination = {
        orderBy: { field: 'createdAt', order: -1 },
        page: 1,
        search: '',
        organizationId: null,
    };

    constructor(
        private store: Store,
        private readonly layerService: LayerService,
        private organizationService: OrganizationService,
        private readonly popupService: PopupService,
        private readonly alertService: AlertService,
        private readonly router: Router,
    ) {}

    ngOnInit() {
        //init select empty organization
        this.organizationService.setOrganization(null);
        // this.getAllOrganizations();

        this.loggedUser$
            .pipe(untilDestroyed(this))
            .subscribe((data: any) => (this.loggedUser = data));

        this.isLoading$!.pipe(untilDestroyed(this)).subscribe((loadingState) => {
            this.isLoading = loadingState;
        });

        this.organizations$.pipe(untilDestroyed(this)).subscribe((organizations) => {
            this.dataSource = new MatTableDataSource<OrganizationTableElement>(
                organizations.map((organization) => ({
                    id: organization.id,
                    createdAt: organization.createdAt,
                    name: organization.name,
                    userCount: organization.userCount,
                    lastConnexionAt: organization.lastConnexionAt,
                    actions: this._getActions(),
                })),
            );
        });
    }

    async getAllOrganizations(): Promise<void> {
        console.log('organization');
        this.store.dispatch(new GetAllOrganizations());
    }

    public openConcessionOrga(organization: Organization): void {
        void this.router.navigate(['admin/organization-detail/' + organization.id + '/general']);
    }

    private deleteOrganization(organization: Organization): void {
        this.popupService
            .openPopup({
                title: "Suppression de l'organisation",
                htmlContent: `Supprimer l’organisation supprimera aussi les utilisateurs qui y sont rattachés. Confirmez-vous la suppression de l’organisation <b>${organization.name}</b> ?`,
                confirmButtonText: "Supprimer l'organisation",
                type: PopupTypes.Delete,
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.store
                        .dispatch(new DeleteOrganization({ organizationId: organization?.id }))
                        .pipe(untilDestroyed(this))
                        .subscribe({
                            next: () => {
                                this.alertService.valid(
                                    'Suppression',
                                    "L'organisation a bien été supprimée",
                                );
                            },
                            error: (err) => {
                                this.alertService.error(
                                    'Suppression',
                                    "Une erreur est survenue : Impossible de supprimer l'organisation",
                                );
                            },
                        });
                }
            });
    }

    private updateOrganization(organization: Organization): void {
        void this.router.navigate(['admin/organization-detail/' + organization.id + '/general']);
    }

    public openModalCreateOrganization(): void {
        this.layerService.show(OrganizationCreateComponent, {
            size: LayerSize.Medium,
            withClosePopup: LayerPopupOptions.Creating,
        });
    }

    private _getActions(): DropdownMenuItem[] {
        const actions: DropdownMenuItem[] = [
            {
                icon: 'icon-edit-02',
                text: "Editer l'organisation",
                outputEventString: OrganizationMenuEvent.Update,
            },
            {
                icon: 'icon-switch-horizontal-01',
                text: 'Accéder à la concession',
                outputEventString: OrganizationMenuEvent.AccessConcession,
            },
            {
                icon: 'icon-trash-01',
                text: "Supprimer l'organisation",
                outputEventString: OrganizationMenuEvent.Delete,
            },
        ];

        return actions;
    }

    public onDropdownMenuClick(organization: Organization, event: string): void {
        switch (event) {
            case OrganizationMenuEvent.AccessConcession:
                this.openConcessionOrga(organization);
                break;
            case OrganizationMenuEvent.Update:
                this.updateOrganization(organization);
                break;
            default:
                this.deleteOrganization(organization);
                break;
        }
    }

    public async goToDetail(idOrganization: number): Promise<void> {
        void this.router.navigate(['admin/organization-detail/' + idOrganization + '/general']);
    }

    public applyFilter(event: string): void {
        this.dataSource.filter = event.trim().toLowerCase();
    }
}
