<div class="page-content">
    <div class="page-header">
        <h1>Fichiers</h1>
        <div class="page-description">Page de gestion des fichiers</div>
    </div>

    <div class="page-body">
        <div class="input-file-container">
            <ed-input-file
                innerId="edInputFileShowCase"
                [allowedFormat]="['SVG', 'PNG', 'JPG', 'GIF']"
                [isUploading]="isUploading"
                (onSubmit)="onFileUpload($event)"></ed-input-file>
        </div>
    </div>

    <ed-table
        *ngIf="dataSource.data.length"
        (onDropdownMenuClick)="onDropdownMenuClick($event.object, $event.event)"
        [dataSource]="dataSource"
        [displayedColumns]="displayedColumns"
        [areActionsInElements]="true">
    </ed-table>
</div>
