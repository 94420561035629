<div class="page-content">
    <div class="page-header">
        <h1>Statistiques</h1>
        <div class="page-description">Page de statistiques</div>
    </div>

    <div class="page-body">
        <p>Stats works!</p>

        <div class="cards-container">
            <div class="row">
                @for (card of fourCards; track card) {
                    <div class="col-sm-6 col-lg-3 g-3">
                        <ed-card
                            [title]="card.title"
                            [body]="card.body"
                            [badge]="card.badge"
                            [badgeStyle]="card.badgeStyle"
                            [footer]="card.footer"
                            class="h-100"></ed-card>
                    </div>
                }
            </div>

            <div class="row">
                @for (card of threeCards; track card) {
                    <div class="col-sm-6 col-lg-4 g-3">
                        <ed-card
                            [title]="card.title"
                            [body]="card.body"
                            [badge]="card.badge"
                            [badgeStyle]="card.badgeStyle"
                            class="h-100"></ed-card>
                    </div>
                }
            </div>
        </div>
    </div>
</div>
