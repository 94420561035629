import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CdkScrollable, OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';

import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxsModule } from '@ngxs/store';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';

import * as Sentry from '@sentry/angular';

import {
    AlertModule,
    DropdownMenuModule,
    TextfieldModule,
    LayerModule,
} from '@ed---interne/ng-uui-components';

import { initializeAppFactory } from './app.initializer';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from './core/core.module';
import { AuthState } from './core/states/auth.state';

import { SharedModule } from './shared/shared.module';

import { UsersState } from './features/admin/users/users.state';
import { DocumentsState } from './shared/states/documents.state';
import { AuthService } from './core/services/auth.service';
import { UsersPaginationState } from './features/admin/users/users-pagination.state';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { OrganizationState } from './features/admin/organization/organization.state';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        FormsModule,
        CoreModule,
        SharedModule,
        TextfieldModule,
        LayerModule,
        AlertModule,
        BrowserAnimationsModule,
        CdkScrollable,
        DropdownMenuModule,
        OverlayModule,
        MatMenuModule,
        ReactiveFormsModule,
        NgxTippyModule,
        NgxsModule.forRoot([
            AuthState,
            UsersState,
            DocumentsState,
            UsersPaginationState,
            OrganizationState,
        ]),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [AuthService, Sentry.TraceService],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS,
            useValue: {
                expandedHeight: '28px',
                collapsedHeight: '28px',
            },
        },
        provideEnvironmentNgxMask(),
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
