<div class="ed-card">
    <div class="ed-card-title" *ngIf="!!title">
        <h6>{{ title }}</h6>
    </div>

    <div class="ed-card-body">
        <div class="card-text" *ngIf="!!body">
            {{ body }}
        </div>
        <div class="badge rounded-pill" [ngClass]="badgeStyle" *ngIf="!!badge">
            {{ badge }}
        </div>
    </div>

    <div class="ed-card-footer" *ngIf="!!footer">
        {{ footer }}
    </div>
</div>
