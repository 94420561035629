import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { User } from '../../../core/models/user.model';
import { UsersService } from './users.service';
import { Pagination, PropertyCount } from '../../../shared/all.types';
import { Observable, tap } from 'rxjs';
import { UsersPageSize } from '../../../shared/all.constants';
import { SetUsersPagination } from './users-pagination.state';

/** This file is divided in 3 parts:
 * - The definition of the Store : defining data structure
 * - Constants that allow NgXs to trigger Actions
 * - Selectors & Actions : Functions allowed to interact with the store
 *
 */

// Store definition
export interface UsersStateModel {
    users: User[] | null;
    isLoading: boolean;
    hasMoreUsers: boolean;
}

//Constants

export class GetUsers {
    static readonly type = 'ADMIN/USERS/ALL';
}

export class DeleteUser {
    static readonly type = 'ADMIN/USERS/DELETE';
    constructor(public payload: { user: User; organizationId: string | null }) {}
}

export class UpdateUser {
    static readonly type = 'ADMIN/USERS/UPDATE';
    constructor(public payload: { user: Partial<User> }) {}
}

export class CreateUser {
    static readonly type = 'ADMIN/USERS/CREATE';
    constructor(public payload: { user: Partial<User> }) {}
}

export class GetPaginatedUsers {
    static readonly type = 'ADMIN/GET_USERS_BATCH';

    constructor(
        public payload?: Pagination,
        public reset?: boolean,
    ) {}
}

export class GetUserById {
    static readonly type = 'ADMIN/USERS/ID';
}

export class GetPropertyCountsSuccess {
    static readonly type = 'ADMIN/GET_PROPERTY_COUNTS_SUCCESS';

    constructor(public propertyCounts: PropertyCount[]) {}
}

export class GetProperty {
    static readonly type = 'PROPERTY/GET_PROPERTY';

    constructor(public id: number) {}
}

export class ChangePassword {
    static readonly type = 'ADMIN/USERS/CHANGE_PASSWORD';
    constructor(
        public payload: {
            userId: string;
            currentPassword: string;
            newPassword: string;
        },
    ) {}
}

//Actions

@State<UsersStateModel>({
    name: 'AdminUsers',
    defaults: {
        users: [],
        isLoading: false,
        hasMoreUsers: false,
    },
})
@Injectable()
export class UsersState {
    @Selector()
    public static users(state: UsersStateModel): User[] | null {
        return state.users;
    }

    @Selector()
    public static hasMoreUsers(state: UsersStateModel): boolean {
        return state.hasMoreUsers;
    }

    constructor(
        private usersService: UsersService,
        private readonly store: Store,
    ) {}

    @Action(GetUsers)
    public async getUsers(ctx: StateContext<UsersStateModel>): Promise<void> {
        ctx.patchState({ isLoading: true });

        const users = await this.usersService.getUsers();
        ctx.patchState({ users: users, isLoading: false });
    }

    @Action(GetUserById)
    public async getUserById(ctx: StateContext<UsersStateModel>): Promise<void> {
        ctx.patchState({ isLoading: true });

        const users = await this.usersService.getUsers();
        ctx.patchState({ users: users, isLoading: false });
    }

    @Action(UpdateUser)
    public async updateUser(ctx: StateContext<UsersStateModel>, action: UpdateUser): Promise<void> {
        try {
            const updatedUser = await this.usersService.updateUser(action.payload.user);
            const currentState = ctx.getState();
            const currentUsers = currentState.users || [];

            const updatedUsers = currentUsers.map((user) =>
                user.id === updatedUser.id ? updatedUser : user,
            );
            ctx.patchState({ users: updatedUsers });
        } catch (err: any) {
            console.log(err);
        }
    }

    @Action(CreateUser)
    public async createUser(ctx: StateContext<UsersStateModel>, action: CreateUser): Promise<User> {
        try {
            const newUser = await this.usersService.createUser(action.payload.user);
            const currentState = ctx.getState();
            const currentUsers = currentState.users || [];
            const updatedUsers = [...currentUsers, newUser];
            ctx.patchState({ users: updatedUsers });
            return newUser;
        } catch (err: any) {
            console.error('Error creating user:', err);
            throw err;
        }
    }

    @Action(DeleteUser)
    public async deleteUser(ctx: StateContext<UsersStateModel>, action: DeleteUser): Promise<void> {
        try {
            const success = await this.usersService.deleteUser(
                action.payload.user,
                action.payload.organizationId,
            );

            const currentState = ctx.getState();
            const currentUsers = currentState.users || []; // Si null, initialiser comme tableau vide

            const updatedUsers = currentUsers.filter((user) => user.id !== action.payload.user.id);
            ctx.patchState({ users: updatedUsers });
        } catch (err: any) {
            console.log(err);
            throw err;
        }
    }

    @Action(GetPaginatedUsers)
    public getPaginatedUsers(
        ctx: StateContext<UsersStateModel>,
        action: GetPaginatedUsers,
    ): Observable<User[]> {
        if (!action.payload || action.reset) {
            this.store.dispatch(new SetUsersPagination(action.payload, action.reset));
            ctx.patchState({ users: null });
        }

        this.store.dispatch(new SetUsersPagination(action.payload));
        const currentUsers = ctx.getState().users || [];

        return this.usersService.getPaginatedUsers(action.payload).pipe(
            tap((result: User[]) => {
                ctx.patchState({
                    users: currentUsers.concat(result || []),
                    hasMoreUsers: result.length === UsersPageSize,
                });
            }),
        );
    }

    @Action(ChangePassword)
    public async changePassword(
        ctx: StateContext<UsersStateModel>,
        action: ChangePassword,
    ): Promise<void> {
        try {
            const userId = action.payload.userId;
            const currentPassword = action.payload.currentPassword;
            const newPassword = action.payload.newPassword;
            await this.usersService.changePassword(userId, currentPassword, newPassword);
        } catch (error: any) {
            console.error('Error changePassword', error);
            throw error;
        }
    }
}
