<div class="page-content">
    <div class="page-header">
        <h1>Page d'accueil</h1>
        <div class="page-description">Bienvenue !</div>
    </div>

    <div class="page-body">
        <p>Home works!</p>
        <ed-button (click)="throwTestError()">Test Sentry Error</ed-button>
    </div>
</div>
