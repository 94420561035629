<div
    [id]="innerId"
    class="edInputFile d-flex justify-content-center text-center"
    (click)="fileInputDrag.click()"
    (appDropZone)="upload($event)"
    onkeypress="">
    <input #fileInputDrag type="file" (change)="uploadByEvent($event)" hidden />
    <div>
        <div class="circle-gray-light">
            <div class="circle-gray">
                <i class="bi bi-cloud-arrow-up" style="font-size: 2em"></i>
            </div>
        </div>

        <div><strong>Cliquez pour télécharger</strong> ou glissez-déposez votre image</div>
        <div *ngIf="allowedFormat">
            {{ allowedFormat.join(', ') }}
        </div>
    </div>
</div>
