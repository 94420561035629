import { Component, Input } from '@angular/core';

@Component({
    selector: 'ed-card',
    templateUrl: './ed-card.component.html',
    styleUrls: ['./ed-card.component.scss'],
})
export class EdCardComponent {
    @Input() title: string | undefined;
    @Input() body: string | undefined;
    @Input() badge: string | undefined;
    @Input() badgeStyle: string | undefined;
    @Input() footer: string | undefined;
}
