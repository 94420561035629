<div class="page-content">
    <div class="page-header">
        <h1>Contacts</h1>
        <div class="page-description">Contacts description</div>
    </div>

    <div class="page-body">
        <p>Contact works!</p>
    </div>
</div>
