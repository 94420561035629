import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RestService } from '../../../core/services/rest.service';
import { User } from '../../../core/models/user.model';

import { Pagination } from '../../../shared/all.types';
import { UsersPageSize } from '../../../shared/all.constants';

import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    private _users = false;

    constructor(
        @Inject(RestService) private restService: RestService,
        private readonly http: HttpClient,
    ) {}

    public get users(): boolean {
        return this._users;
    }

    private _getBaseUrl(user: string): string {
        const baseUrl = `${environment.apiUrl}${user}`;
        return baseUrl;
    }

    public async getUsers(): Promise<User[]> {
        const path = '/user/all';
        const users = await this.restService.get(path, {});
        return users;
    }

    public async getUserById(userId: number): Promise<User> {
        const path = `/user/infos/${userId}`;
        const user = await this.restService.get(path, {});
        return user;
    }

    public async deleteUser(user: User, organizationId: any): Promise<void> {
        user.organization = { id: organizationId };
        const path = `/user/${user.id}`;
        return await this.restService.delete(path, {});
    }

    public async updateUser(user: Partial<User>): Promise<User> {
        const path = `/user`;
        return await this.restService.put(path, {}, user);
    }

    public async createUser(user: Partial<User>): Promise<User> {
        const path = `/user`;
        return await this.restService.post(path, {}, user);
    }

    public getPaginatedUsers(paginationInfo?: Pagination): Observable<User[]> {
        const pagination = {
            ...paginationInfo,
            perPage: UsersPageSize,
        };
        const path = `/user/get`;
        return this.http.post<User[]>(this._getBaseUrl(path), pagination, {});
    }

    public async changePassword(
        userId: string,
        currentPassword: string,
        newPassword: string,
    ): Promise<void> {
        const path = `/user/changePassword/${userId}`;
        await this.restService.put(
            path,
            {},
            { newPassword: newPassword, currentPassword: currentPassword },
        );
    }
}
