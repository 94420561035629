import { Component, inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { filter, Observable, Subscription } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService, ButtonColors, LayerService } from '@ed---interne/ng-uui-components';

import { User } from 'src/app/core/models/user.model';

import { CreateOrganization, OrganizationState } from '../organization.state';
import { Organization } from 'src/app/core/models/organization.model';
import { OrganizationService } from '../organization.service';

@UntilDestroy()
@Component({
    selector: 'organization-create',
    templateUrl: './organization-create.component.html',
    styleUrls: ['./organization-create.component.scss'],
})
export class OrganizationCreateComponent implements OnDestroy {
    public buttonColors = ButtonColors;

    public user!: User;
    public password: string | null = null;
    public passwordConfirm: string | null = null;
    public header: any;
    public orgaFormGroup: FormGroup = this.formBuilder.group({
        name: [''],
    });

    private avatarFile: File | undefined;
    public avatarUrl: string | undefined;

    private _subscriptions: Subscription[] = [];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService,
        private readonly store: Store,
        private readonly alertService: AlertService,
        private organizationService: OrganizationService,
    ) {
        this.orgaFormGroup = this.formBuilder.group({
            name: [''],
        });
    }

    public ngOnDestroy(): void {
        for (const subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
    }

    public valid(): void {
        const { name } = this.orgaFormGroup.value;
        const organization: Partial<Organization> = {
            name: name,
        };

        const createOrganizationSubscription = this.store
            .dispatch(new CreateOrganization({ organization: organization }))
            .subscribe({
                next: () => {
                    this.alertService.valid('Organisation', 'Organisation créée !');
                },
                error: (err) =>
                    this.alertService.error(
                        'Organisation',
                        "Une erreur est survenue : Impossible de créer l'organisation",
                    ),
            });

        this._subscriptions.push(createOrganizationSubscription);

        const avatarSubscription = this.store
            .select(OrganizationState.createdOrganization)
            .pipe(
                filter((organization): organization is Organization => organization !== null), // Ignore null values
            )
            .subscribe(async (organization) => {
                if (!!organization && this.avatarFile) {
                    this.organizationService.uploadAvatar(organization.id, this.avatarFile);
                }
                this.close();
            });
        this._subscriptions.push(avatarSubscription);
    }

    public close(): void {
        this.layerService.close();
    }

    public isCreateDisabled(): boolean {
        return this.orgaFormGroup.invalid || !this.orgaFormGroup.dirty;
    }

    public onFileSelect(event: any): void {
        console.log(event);
        this.avatarFile = event.target.files[0];
        this.avatarUrl = URL.createObjectURL(this.avatarFile!);
    }

    public showPopupOnLayerClose(): boolean {
        return this.orgaFormGroup.dirty;
    }
}
