import { Component } from '@angular/core';

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss'],
})
export class StatsComponent {
    public fourCards = [
        {
            title: 'Date signature contrat de concession',
            body: '2021',
            badge: '',
            badgeStyle: '',
            footer: 'Hell yeah!',
        },
        {
            title: 'Durée du contrat de concession en vigueur',
            body: '4 ans',
            badge: '',
            badgeStyle: '',
        },
        {
            title: 'Nom du délégataire',
            body: 'Enedis',
            badge: '',
            badgeStyle: '',
        },
        {
            title: 'Exercice traité',
            body: '3e année',
            badge: '+',
            badgeStyle: '',
        },
    ];

    public threeCards = [
        {
            title: 'Taux d’enfouissement HTA',
            body: '62 %',
            badge: '+ 2%',
            badgeStyle: 'success',
        },
        {
            title: 'Critère B HIX',
            body: '12 min',
            badge: '+ 1 min',
            badgeStyle: 'warning',
        },
        {
            title: 'Investissements totaux',
            body: '32 M€',
            badge: '- 2M',
            badgeStyle: 'danger',
        },
    ];
}
