import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService, ButtonColors, LayerService } from '@ed---interne/ng-uui-components';
import { User } from 'src/app/core/models/user.model';
import { UpdateUser, GetUsers, GetPaginatedUsers } from 'src/app/features/admin/users/users.state';
import { emailValidator } from 'src/app/shared/validators/email.validator';
import { passwordValidator } from 'src/app/shared/validators/password.validator';
import { Organization } from 'src/app/core/models/organization.model';

@UntilDestroy()
@Component({
    selector: 'user-update',
    templateUrl: './user-update.component.html',
    styleUrls: ['./user-update.component.scss'],
})
export class UserUpdateComponent {
    public buttonColors = ButtonColors;

    public user!: User;
    public password: string | null = null;
    public passwordConfirm: string | null = null;
    public header: any;
    public organizationId: any;
    public userFormGroup: FormGroup = this.formBuilder.group({
        email: [{ value: '', disabled: false }, [emailValidator()]],
        password: ['', [passwordValidator()]],
        firstname: [''],
        lastname: [''],
        passwordConfirm: ['', [passwordValidator()]],
        isAdmin: false,
    });

    constructor(
        private readonly alertService: AlertService,
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService,
        private readonly store: Store,
    ) {
        this.userFormGroup = this.formBuilder.group({
            email: [{ value: '', disabled: true }, [emailValidator()]],
            password: ['', [passwordValidator()]],
            firstname: [''],
            lastname: [''],
            passwordConfirm: ['', [passwordValidator()]],
            isAdmin: [false],
        });
    }

    public valid(): void {
        const orgaToCreate: Partial<Organization> = { id: this.organizationId };
        const userToUpdate: Partial<User> = {
            id: this.user.id,
            mail: this.userFormGroup.value.email as string,
            firstname: this.userFormGroup.value.firstname as string,
            lastname: this.userFormGroup.value.lastname as string,
            password: this.userFormGroup.value.password as unknown as string,
            isAdmin: this.userFormGroup.value.isAdmin as boolean,
            isActive: false,
            createdAt: this.user.createdAt,
            updatedAt: this.user.updatedAt,
            lastConnexionAt: this.user.lastConnexionAt,
            organization: orgaToCreate,
        };
        this.store.dispatch(new UpdateUser({ user: userToUpdate })).subscribe({
            next: () => {
                this.alertService.valid('Utilisateurs', "L'utilisateur a bien été modifié");
            },
            error: (err) => {
                this.alertService.error(
                    'Enregistrement',
                    "une erreur est survenue : Impossible de mettre à jour l'utilisateur",
                );
            },
        });

        this.close();
    }

    isMail(input: string): boolean {
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return expression.test(input);
    }

    public set params(params: { header: any; user: User; organizationId: any }) {
        this.user = params.user;
        this.header = params.header;
        this.organizationId = params.organizationId;

        this.userFormGroup.patchValue({
            email: this.user.mail,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            isAdmin: this.user.isAdmin,
            password: '',
            passwordConfirm: '',
        });
    }

    public close(): void {
        this.layerService.close();
    }

    public canCreateEdit(): boolean {
        return this.userFormGroup.invalid || !this.userFormGroup.dirty;
    }

    public showPopupOnLayerClose(): boolean {
        return this.userFormGroup.dirty;
    }
}
