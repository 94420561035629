import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DropdownMenuItem } from '@ed---interne/ng-uui-components';
import { Subscription } from 'rxjs';
import { UnknownLabel } from '../../all.constants';
import { documentExtToIcon } from '../../enums/document.enums';
import {
    DisplayParticipantType,
    ENERGY_TAG_MAP,
    ParticipantType,
} from '../../enums/property.enums';

export enum DisplayType {
    TEXT = 0,
    DATE = 1,
    LABELS = 4,
    ICON = 5,
    FILE = 6,
    AVATAR = 7,
    NUMBER = 8,
    BADGE = 9,
}

/**
 * Sent when there is a click on the actions drop down
 */
interface DropDownMenuClickData {
    object: any;
    event: string;
}

/**
 * Sent when there is a click on a column
 */
export interface ElementClickData {
    object: any;
    objectKey: string;
    event?: any;
}

export interface DisplayedColumns {
    /** Key used to access the object property */
    objectKey: string;
    /** Key used in text display type to access a secondary object property */
    objectSecondKey?: string;
    /** String showed as header of the column */
    objectDisplayName?: string;
    /** If true, the column will be bold */
    bold?: boolean;
    /** If set, will check the corresponding key in the dataSource to set the column bold */
    boldKey?: string;
    /** If true, the column will be italic */
    italic?: boolean;
    /** If set, will check the corresponding key in the dataSource to set the column italic */
    italicKey?: string;
    displayType: DisplayType;
    /** Trigger an event when there is a click on this column, containing the object and the key */
    clickable?: boolean;
    /** Icon string for icon display type */
    icon?: string;
    /** Disable sort on this column */
    disableSort?: boolean;
}

@UntilDestroy()
@Component({
    selector: 'ed-table',
    templateUrl: './ed-table.component.html',
    styleUrls: ['./ed-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EdTableComponent implements AfterViewInit, OnDestroy {
    public participantType = DisplayParticipantType;
    public displayType = DisplayType;
    public energyTagMap = ENERGY_TAG_MAP;
    public sortColumn!: string;
    public sortDirection!: SortDirection;

    @Input() set dataSource(value: MatTableDataSource<any>) {
        this._dataSource = value;
        this._dataSource.sort = this.sort;
        this._tagLastSubRows();
    }
    public _dataSource!: MatTableDataSource<any>;

    @Input() set filter(filter: string) {
        this._dataSource.filter = filter.trim().toLowerCase();
    }

    @Input() set sortInfo(orderBy: { field: string; order: number } | undefined) {
        if (orderBy) {
            this.sortColumn = orderBy.field;
            this.sortDirection = orderBy.order === 1 ? 'asc' : 'desc';
        }
    }

    @Input() set displayedColumns(value: DisplayedColumns[]) {
        this._displayedColumns = value;

        for (const displayedColumn of this._displayedColumns) {
            this.columnsKeys.push(displayedColumn.objectKey);
        }
    }

    public _displayedColumns: DisplayedColumns[] | undefined;
    public columnsKeys: string[] = [];
    private _subscriptions: Subscription[] = [];

    /**
     * this means the actions are determined by the element of the row, instead of being global for the whole list
     * if true, it will look for the actions structure in the element object, instead of _actionsMenuList
     */
    @Input() set areActionsInElements(value: boolean) {
        this._areActionsInElements = value;

        if (value) {
            this.columnsKeys.push('actions');
        }
    }
    public _areActionsInElements: boolean | undefined;

    @Input() set actionsMenuList(value: DropdownMenuItem[]) {
        this._actionsMenuList = value;
        this.columnsKeys.push('actions');
    }
    public _actionsMenuList!: DropdownMenuItem[];

    @Output()
    public onRowClick = new EventEmitter<number>(); // id of the row item

    @Output()
    public onDropdownMenuClick = new EventEmitter<DropDownMenuClickData>();

    @Output()
    public onElementClick = new EventEmitter<ElementClickData>();

    @Output()
    public onSortChanged = new EventEmitter<MatSort>();

    @ViewChild(MatSort)
    public sort!: MatSort;

    public unknownLabel = UnknownLabel;

    public ngAfterViewInit(): void {
        if (this._dataSource) {
            this._dataSource.sort = this.sort;
            this._subscriptions.push(
                this.sort.sortChange.pipe(untilDestroyed(this)).subscribe(() => {
                    this.onSortChanged.emit(this.sort);
                }),
            );
        }
    }

    public ngOnDestroy(): void {
        for (const sub of this._subscriptions) {
            sub.unsubscribe();
        }
    }

    public isSubRow(_index: number, data: any): boolean {
        return !!data.isSubRow;
    }

    private _tagLastSubRows(): void {
        this._dataSource.filteredData.forEach((row, index) => {
            if (row.isSubRow && !this._dataSource.filteredData[index + 1]?.isSubRow) {
                row.isLastSubRow = true;
            }
        });
    }

    public rowClicked(id: number): void {
        this.onRowClick.emit(id);
    }

    public onDropdownMenuClicked(object: object, event: string): void {
        this.onDropdownMenuClick.emit({ object: object, event: event });
    }

    public showDisplay(displayType: DisplayType, showDisplay: DisplayType): boolean {
        if (displayType === showDisplay) {
            return true;
        }

        return !displayType && showDisplay === DisplayType.TEXT;
    }

    public centerContent(displayType: DisplayType): boolean {
        return [DisplayType.DATE, DisplayType.NUMBER].includes(displayType);
    }

    public elementClicked(object: object, objectKey: string, event?: any): void {
        this.onElementClick.emit({ object, objectKey, event });
    }

    public getFileIcon(extension: string): string {
        let icon = 'icon-file-uknown';
        documentExtToIcon.forEach((extIcon, extensions) => {
            if (extensions.some((ext) => ext === extension)) {
                icon = extIcon;
            }
        });

        return icon;
    }

    public getAvatarUrl(avatarPath: string): string {
        if (!avatarPath) {
            return '';
        }

        return avatarPath;
    }

    public isAvatarTypeOrg(type: ParticipantType): boolean {
        return (
            type === ParticipantType.RenovationOperator || type === ParticipantType.PropertyManager
        );
    }
}
