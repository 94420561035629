import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertModule, ComponentsModule } from '@ed---interne/ng-uui-components';

import { SidenavComponent } from '../../shared/components/sidenav/sidenav.component';
import { MainComponent } from './main.component';
import { HomeComponent } from './home/home.component';
import { StatsComponent } from './stats/stats.component';
import { ContactComponent } from './contact/contact.component';
import { FilesComponent } from './files/files.component';
import { AccountComponent } from './settings/account/account.component';
import { AccountPasswordEditComponent } from './settings/edit-password/account-password-edit.component';

import { UsersComponent } from '../admin/users/users.component';

import { SkeletonListComponent } from '../../shared/components/skeleton-list/skeleton-list.component';

import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { EdTableModule } from '../../shared/components/ed-table/ed-table.module';

import { InfiniteScrollDirective } from '../../shared/directives/infinite-scroll.directive';

import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { OrganizationComponent } from '../admin/organization/organization.component';

@NgModule({
    declarations: [
        MainComponent,
        SidenavComponent,
        HomeComponent,
        StatsComponent,
        ContactComponent,
        UsersComponent,
        FilesComponent,
        AccountComponent,
        AccountPasswordEditComponent,
        OrganizationComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        FormsModule,
        SharedModule,
        ComponentsModule,
        CoreModule,
        SkeletonListComponent,
        EdTableModule,
        InfiniteScrollDirective,
        ReactiveFormsModule,
        MatMenuModule,
        MatExpansionModule,
        MatSidenavModule,
        MatTableModule,
        MatSortModule,
        AlertModule,
    ],
})
export class MainModule {}
