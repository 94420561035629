<div class="modal-content">
    <div class="modal-content-header">
        <h1>{{ header }}</h1>
    </div>

    <div class="modal-content-body" [formGroup]="userFormGroup">
        <div>
            <label for="email" class="required">Email de l'utilisateur</label>
            <ed-textfield
                innerId="email"
                name="email"
                type="email"
                placeholder="jeanne.martin@aecenergie.fr"
                [required]="true"
                [formControlName]="'email'"></ed-textfield>
        </div>

        <div class="horizontal-form">
            <div>
                <label for="firstname" class="required">Prénom</label>
                <ed-textfield
                    innerId="firstname"
                    name="firstname"
                    type="text"
                    placeholder="Jeanne"
                    [required]="true"
                    formControlName="firstname"></ed-textfield>
            </div>

            <div>
                <label for="lastname" class="required">Nom</label>
                <ed-textfield
                    innerId="lastname"
                    name="lastname"
                    type="text"
                    placeholder="Martin"
                    [required]="true"
                    formControlName="lastname"></ed-textfield>
            </div>
        </div>
        <!-- <div class="form-row">
            <div class="half-width checkboxAdmin">
                <input type="checkbox" formControlName="isAdmin" class="form-check-input" />
                Administrateur
            </div>
        </div> -->
    </div>

    <div class="modal-content-footer">
        <ed-button [colorSchema]="buttonColors.Secondary" (click)="close()">Retour</ed-button>
        <ed-button (click)="valid()" [disabled]="canCreateEdit()">Ajouter l'utilisateur</ed-button>
    </div>
</div>
