import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AlertService, ButtonColors, LayerService } from '@ed---interne/ng-uui-components';
import { User } from 'src/app/core/models/user.model';
import { UpdateUser, GetUsers } from 'src/app/features/admin/users/users.state';
import { emailValidator } from 'src/app/shared/validators/email.validator';
import { passwordValidator } from 'src/app/shared/validators/password.validator';
import { Organization } from 'src/app/core/models/organization.model';
import { UpdateOrganization } from '../organization.state';
import { map, Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'organization-manage-studies',
    templateUrl: './organization-manage-studies.component.html',
    styleUrls: ['./organization-manage-studies.component.scss'],
})
export class OrganizationManageStudiesComponent implements OnInit {
    public organizationId: string | null = null;

    constructor(
        private readonly alertService: AlertService,
        private readonly formBuilder: FormBuilder,
        private readonly layerService: LayerService,
        private readonly store: Store,
        private readonly route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.route.paramMap.pipe(untilDestroyed(this)).subscribe((paramMap) => {
            this.organizationId = paramMap.get('id');
        });
    }
}
